<template>
  <span :endTime="endTime" :endText="endText">
    <slot>
      <div v-html="content"></div>
    </slot>
  </span>
</template>
<script>
export default {
  data() {
    return {
      content: "",
      textEnd: "",
      timer: null,
      textHour: "",
      textMin: "",
      textDay: "",
      textSec: "",
      textEnd: "",
    };
  },
  props: ["lang", "endTime", "endText"],
  watch: {
    //监听时间的变化
    endTime() {
      this.countdowm(this.endTime);
    },
  },
  watch: {
    lang(newV, oldV) {
      // console.log("lang", newV);
      // clearInterval(this.timer);
      // this.countdowm();
      this.getTxt();
    },
  },
  mounted() {
    console.log(this.lang);
    this.getTxt();
    clearInterval(this.timer);
    this.countdowm(this.endTime);
  },
  methods: {
    getTxt() {
      this.textDay = this.lang == "en" ? "Day" : "天";
      this.textHour = this.lang == "en" ? "Hour" : "小时";
      this.textMin = this.lang == "en" ? "Minute" : "分钟";
      this.textSec = this.lang == "en" ? "Second" : "秒";
      this.textEnd = this.lang == "en" ? "The conference has ended" : "大会已结束";
    },
    countdowm(timestamp) {
      let self = this;

      this.timer = setInterval(function () {
        let nowTime = new Date();
        let endTime = new Date(timestamp * 1000);
        let t = endTime.getTime() - nowTime.getTime();
        if (t > 0) {
          let day = Math.floor(t / 86400000);
          let hour = Math.floor((t / 3600000) % 24);
          let min = Math.floor((t / 60000) % 60);
          let sec = Math.floor((t / 1000) % 60);
          hour = hour < 10 ? "0" + hour : hour;
          min = min < 10 ? "0" + min : min;
          sec = sec < 10 ? "0" + sec : sec;
          let format = "";

          // <!-- <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          //   <dd class="order-first text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">10天</dd>
          // </div> -->
          if (day > 0) {
            format = `<span class="order-first text-xl text-gray-900 text-center pr-5">${day}${self.textDay}</span><span class="order-first text-xl text-gray-900 text-center pr-5">${hour}${self.textHour}</span><span class="order-first text-xl text-gray-900 text-center pr-5">${min}${self.textMin}</span><span class="order-first text-xl text-gray-900 text-center pr-5">${sec}${self.textSec}</span>`;
          }
          if (day <= 0 && hour > 0) {
            format = `<span class="order-first text-xl text-gray-900 text-center pr-5">${hour}${self.textHour}</span><span class="order-first text-xl text-gray-900 text-center pr-5">${min}${self.textMin}</span><span class="order-first text-xl text-gray-900 text-center pr-5">${sec}${self.textSec}</span>`;
          }
          if (day <= 0 && hour <= 0) {
            format = `<span class="order-first text-xl text-gray-900 text-center pr-5">${min}${self.textMin}</span><span class="order-first text-xl text-gray-900 text-center pr-5">${sec}${self.textSec}</span>`;
          }
          self.content = format;
        } else {
          clearInterval(self.timer);
          self.content = `<span class="order-first text-2xl text-gray-900 text-center text-yellow-400">${self.textEnd}</span>`;
        }
      }, 1000);
    },
  },
};
</script>
