<template>
  <section class="text-gray-600 body-font">
    <div class="container px-5 py-12 mx-auto">
      <h1 class="text-3xl font-medium title-font text-gray-900 mb-12 text-center">
        {{ lang == "en" ? "Conference Organizing Committee" : "大会组委会" }}
      </h1>
      <div class="flex flex-wrap -m-4">
        <div class="p-4 md:w-1/2 w-full">
          <div class="h-full bg-gray-100 p-4 rounded">
            <div class="text-lg text-center text-black mb-5 mt-2 font-bold">
              {{ lang == "en" ? "International Advisory Board" : "国际顾问委员会" }}
            </div>
            <p class="text-lg text-black leading-relaxed mb-3">{{ lang == "en" ? "Chairman" : "主席" }}</p>
            <a v-for="item in memberList.member10" :key="item.id" class="whitespace-nowrap w-1/2 overflow-hidden">
              <span class="flex-grow flex">
                <span class="title-font pr-3 text-sm text-gray-900 mb-3">{{ item.name }} </span>
                <span class="text-gray-500 text-sm">{{ item.company }}</span>
              </span>
            </a>
            <p class="text-lg text-black leading-relaxed my-3">{{ lang == "en" ? "Co-chair" : "联合主席" }}</p>
            <a v-for="item in memberList.member11" :key="item.id" class="whitespace-nowrap w-1/2 overflow-hidden">
              <span class="flex-grow flex">
                <span class="title-font pr-3 text-sm text-gray-900 mb-3">{{ item.name }} </span>
                <span class="text-gray-500 text-sm">{{ item.company }}</span>
              </span>
            </a>
            <p class="text-lg text-black leading-relaxed my-3">{{ lang == "en" ? "Member" : "成员" }}</p>
            <a v-for="item in memberList.member12" :key="item.id" class="whitespace-nowrap w-1/2 overflow-hidden">
              <span class="flex-grow flex">
                <span class="title-font pr-3 text-sm text-gray-900 mb-3">{{ item.name }} </span>
                <span class="text-gray-500 text-sm">{{ item.company }}</span>
              </span>
            </a>
          </div>
        </div>
        <div class="p-4 md:w-1/2 w-full">
          <div class="h-full bg-gray-100 p-4 rounded">
            <div class="">
              <div class="text-lg text-center text-black mb-5 mt-2 font-bold">
                {{ lang == "en" ? "Technical committee" : "技术委员会" }}
              </div>
              <p class="text-lg text-black leading-relaxed mb-3">{{ lang == "en" ? "Chairman" : "主席" }}</p>
              <a v-for="item in memberList.member20" :key="item.id" class="whitespace-nowrap w-1/2 overflow-hidden">
                <span class="flex-grow flex">
                  <span class="title-font pr-3 text-sm text-gray-900 mb-3">{{ item.name }} </span>
                  <span class="text-gray-500 text-sm">{{ item.company }}</span>
                </span>
              </a>
              <p class="text-lg text-black leading-relaxed my-3">{{ lang == "en" ? "Co-chair" : "联合主席" }}</p>
              <a v-for="item in memberList.member21" :key="item.id" class="whitespace-nowrap w-1/2 overflow-hidden">
                <span class="flex-grow flex">
                  <span class="title-font pr-3 text-sm text-gray-900 mb-3">{{ item.name }} </span>
                  <span class="text-gray-500 text-sm">{{ item.company }}</span>
                </span>
              </a>
              <p class="text-lg text-black leading-relaxed my-3">{{ lang == "en" ? "Member" : "成员" }}</p>
              <a v-for="item in memberList.member22" :key="item.id" class="whitespace-nowrap w-1/2 overflow-hidden">
                <span class="flex-grow flex">
                  <span class="title-font text-sm text-gray-900 mb-3">{{ item.name }} </span>
                  <span class="flex-1 box-border pl-3 overflow-hidden overflow-ellipsis text-gray-500 text-sm">{{
                    item.company
                  }}</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="md:w-3/5 w-full mx-auto flex flex-wrap mt-4 bg-gray-100">
        <div class="bg-gray-100 p-4 rounded">
          <div class="text-lg text-center text-black mb-5 mt-2 font-bold">
            {{ lang == "en" ? "Organization Committee" : "组织委员会" }}
          </div>
          <p class="text-lg text-black leading-relaxed mb-3">{{ lang == "en" ? "Chairman" : "主席" }}</p>
          <a v-for="item in memberList.member00" :key="item.id" class="whitespace-nowrap w-1/2 overflow-hidden">
            <span class="flex-grow flex">
              <span class="title-font pr-3 text-sm text-gray-900 mb-3">{{ item.name }} </span>
              <span class="text-gray-500 text-sm">{{ item.company }}</span>
            </span>
          </a>
          <p class="text-lg text-black leading-relaxed my-3">{{ lang == "en" ? "Co-chair" : "联合主席" }}</p>
          <a v-for="item in memberList.member01" :key="item.id" class="whitespace-nowrap w-1/2 overflow-hidden">
            <span class="flex-grow flex">
              <span class="title-font pr-3 text-sm text-gray-900 mb-3">{{ item.name }} </span>
              <span class="text-gray-500 text-sm">{{ item.company }}</span>
            </span>
          </a>
          <p class="text-lg text-black leading-relaxed my-3">{{ lang == "en" ? "Member" : "成员" }}</p>
          <a v-for="item in memberList.member02" :key="item.id" class="whitespace-nowrap w-1/2 overflow-hidden">
            <span class="flex-grow flex">
              <span class="title-font pr-3 text-sm text-gray-900 mb-3">{{ item.name }} </span>
              <span class="text-gray-500 text-sm">{{ item.company }}</span>
            </span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Team",
  props: ["memberList", "lang"],
};
</script>
